<!-- =========================================================================================
    File Name: MySpaces.vue
    Description: Basic view with map to browse and see groupp of placements
========================================================================================== -->

<template>
  <div>
    <init-data> </init-data>
    <placement-sidebar
      v-on:removeMarker="removeMarker"
      :isSidebarActive="isSidebarPlacementActive"
      @closeSidebar="togglePlacementSidebar"
      v-bind:initialSelectedPlacements="selectedPlacements"
    />
    <div>
      <div class="flex flex-end">
        <div>List view</div>
        <div>
          <vs-switch class="ml-2 mb-1" color="success" v-model="isListViewEnabled">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
      </div>
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <div class="flex mb-4">
        <div :class="isListViewEnabled ? 'w-full bg-grid-color-secondary h-12' : 'w-6/12 bg-grid-color-secondary h-12'">
          <vs-table
            ref="table"
            :noDataText="this.$t('MySpaceNoLocation')"
            v-model="selected"
            pagination
            :max-items="itemsPerPage"
            search
            :data="filteredSpaces"
          >
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
              <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                <!-- ACTION - DROPDOWN -->
                <!-- <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

                            <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
                                <span class="mr-2">Actions</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>

                            <vs-dropdown-menu>

                                <vs-dropdown-item>
                                    <span class="flex items-center">
                                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                                        <span>Delete</span>
                                    </span>
                                </vs-dropdown-item>

                                <vs-dropdown-item>
                                    <span class="flex items-center">
                                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                                        <span>Archive</span>
                                    </span>
                                </vs-dropdown-item>

                                <vs-dropdown-item>
                                    <span class="flex items-center">
                                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                                        <span>Print</span>
                                    </span>
                                </vs-dropdown-item>

                                <vs-dropdown-item>
                                    <span class="flex items-center">
                                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                                        <span>Another Action</span>
                                    </span>
                                </vs-dropdown-item>

                            </vs-dropdown-menu>
                        </vs-dropdown> -->

                <!-- ADD NEW -->
                <div class="flex">
                  <!-- <p>{{ `SPACES: ${JSON.stringify(spaces)}` }}</p> -->
                  <!-- <p>{{ `USERROLE: ${getUserRole()}` }}</p> -->
                  <div class="flex-row">
                    <button
                      class="btn-add-new p-4 mr-4 rounded-lg bg-white cursor-pointer flex items-center justify-center text-lg font-medium text-primary border border-solid border-primary disabled:pointer-events-none disabled:bg-warning disabled:border-danger"
                      :disabled="spacesLimitReached"
                      @click="addNew()"
                    >
                      <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
                    </button>
                  </div>
                </div>
                <div class="px-4 py-2 rounded justify-self-start">
                  <p class="text-primary">{{ spacesCountMsg}}</p>
                </div>
              </div>
              <div class="flex">
                <div class="flex-col mr-3">
                  <div class="mb-3"></div>
                  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                    <feather-icon
                      icon="TagIcon"
                      svg-classes="h-8 w-8 mr-2"
                      :class="{
                        'padding-top cursor-pointer ml-2': true,
                        gray: labelsFilterLength == 0,
                        'text-primary': labelsFilterLength > 0,
                      }"
                    ></feather-icon>
                    <vs-dropdown-menu style="z-index: 40001">
                      <vs-dropdown-item v-for="(label, index) in labels" :key="index" @click.stop>
                        <div class="flex">
                          <vs-checkbox :vs-value="label.pid" v-model="labelsFilter">
                            <vs-chip link :color="label.color" class="ml-1 mt-1 product-order-status"
                              >{{ label.name }}
                            </vs-chip>
                          </vs-checkbox>
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click.stop @click="EditSpaceLabels()">
                        <p class="ml-8">Edit Labels</p>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <!-- ITEMS PER PAGE -->
                <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
                  <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                  >
                    <span class="mr-2"
                      >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                      {{
                        filteredSpaces.length - currentPage * itemsPerPage > 0
                          ? currentPage * itemsPerPage
                          : filteredSpaces.length
                      }}
                      {{ $t('Of') }} {{ queriedItems }}</span
                    >
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="itemsPerPage = 4">
                      <span>4</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 10">
                      <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 15">
                      <span>15</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="itemsPerPage = 20">
                      <span>20</span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
            <template slot="thead">
              <vs-th sort-key="name">{{ $t('Name') }}</vs-th>
              <vs-th v-if="labels && labels.length > 0" sort-key="tag">Tags</vs-th>
              <!-- <vs-th sort-key="longitude">Longitude</vs-th> -->
              <vs-th sort-key="found_count">{{ $t('Views') }}</vs-th>
              <!-- <vs-th sort-key="access_level">{{ $t('Visibility') }}</vs-th> -->
              <!-- <vs-th sort-key="range">{{ $t('Range') }}</vs-th> -->
              <vs-th v-show="isListViewEnabled" sort-key="created_at">Created At</vs-th>
              <vs-th sort-key="updated_at_timestamp">Date Modified</vs-th>
              <vs-th sort-key="last_found_timestamp">Last Found</vs-th>
              <!-- <vs-th v-show="isListViewEnabled" sort-key="present_from">Present From</vs-th> -->
              <vs-th v-show="isListViewEnabled" sort-key="present_to">Expiration</vs-th>
              <vs-th v-show="isListViewEnabled" sort-key="range">Range</vs-th>
              <vs-th>Action</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr
                  :state="selected.pid === space.pid ? 'primary' : indexSpace == -1 ? 'danger' : null"
                  :data="space"
                  :key="indexSpace"
                  v-for="(space, indexSpace) in data"
                  class="custom-tr"
                >
                  <vs-td class="font-medium">
                    <div class="flex">
                      <vs-avatar
                        style="min-width: 30px;"
                        class="mr-4"
                        :src="space.thumbnail ? space.thumbnail : require('@/assets/images/hoverlay/Icon-512.png')"
                      />
                      <div style="align-self: center;" v-if="space.name">
                        {{ space.name | truncate(40, '...') }}
                      </div>
                    </div>
                  </vs-td>
                  <vs-td v-if="labels && labels.length > 0" class="font-medium">
                    <!-- {{ space_label.label }} -->
                    <vs-dropdown vs-custom-content vs-trigger-click class="mt-1 cursor-pointer">
                      <feather-icon
                        icon="TagIcon"
                        svg-classes="h-6 w-6 text-gray hover:text-primary stroke-current"
                        class="cursor-pointer light-gray ml-2"
                      ></feather-icon>
                      <vs-dropdown-menu style="z-index: 40001">
                        <vs-dropdown-item v-for="(label, index) in labels" :key="index" @click.stop>
                          <vs-checkbox
                            :vs-value="label.pid"
                            v-model="selectedSpaceLabels"
                            @change="onLabelChanged($event)"
                          >
                            <vs-chip link :color="label.color" class="ml-1 mt-1 product-order-status"
                              >{{ label.name }}
                            </vs-chip></vs-checkbox
                          >
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>

                    <vs-chip
                      :key="indextr"
                      v-for="(space_label, indextr) in getSpaceLabels(space)"
                      link
                      :color="space_label.label.color"
                      class="product-order-status"
                      >{{ space_label.label.name }}
                    </vs-chip>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">{{ space.found_count || '0' }}</p>

                    <!-- <vs-progress :percent="Number(space.popularity)" :color="getPopularityColor(Number(space.popularity))" class="shadow-md" /> -->
                  </vs-td>

                  <!-- <vs-td>
                    <vs-chip :color="getVisibilityColor(tr)" class="product-order-status">
                      <div v-if="new Date() > new Date(space.present_to)">
                        Expire
                      </div>
                      <div v-else>
                        <div v-if="space.access_level == 'everyone'">Public</div>
                        <div v-else>Private</div>
                      </div>
                    </vs-chip>
                  </vs-td> -->
                  <!-- 
                  <vs-td>
                    <p class="product-price">{{ space.range }} m</p>
                  </vs-td> -->
                  <vs-td v-show="isListViewEnabled">
                    {{ dateToString(space.created_at) }}
                  </vs-td>
                  <vs-td>
                    {{ dateToString(space.updated_at) }}
                  </vs-td>
                  <vs-td>
                    {{ space.last_found_timestamp == '0' ? 'never' : dateToDuration(space.last_found_timestamp) }}
                  </vs-td>
                  <!-- <vs-td v-show="isListViewEnabled">
                    {{ dateToString(space.present_from) }}
                  </vs-td> -->
                  <vs-td v-show="isListViewEnabled">
                    {{ dateToString(space.present_to) }}
                  </vs-td>
                  <vs-td v-show="isListViewEnabled">
                    {{ space.range }}
                  </vs-td>
                  <!-- <vs-td>
                    <vs-button @click="editSpace(space.latitude, space.longitude)" class="w-full " color="primary" type="filled">Edit</vs-button>
                  </vs-td> -->
                  <vs-td class="whitespace-no-wrap">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="w-6 h-6 text-gray hover:text-primary stroke-current"
                      class="ml-2 light-gray"
                      @click="editSpace(space.pid)"
                    />
                    <feather-icon
                      icon="SettingsIcon"
                      svgClasses="w-6 h-6 hover:text-primary stroke-current"
                      class="ml-2 light-gray"
                      @click="EditSpaceSettings(space.pid)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="w-6 h-6 hover:text-danger stroke-current"
                      class="ml-2 light-gray"
                      @click="confirmDeleteSpace(space.pid)"
                    />
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
        <div v-show="!isListViewEnabled" class="w-6/12 bg-grid-color-secondary h-12">
          <div class="flex mb-4">
            <div class="w-full bg-grid-color-secondary h-12 p-2">
              <div v-show="spaces == null || spaces.length == 0" class="container">
                <iframe
                  crossorigin="anonymous"
                  class="video"
                  src="https://www.youtube.com/embed/kETl0Z0bNvs"
                  title="YouTube video player"
                  frameborder="0"
                  allow="cross-origin-isolated; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div
                v-show="spaces && spaces.length > 0"
                class="rounded-lg"
                style="height: 570px; width: 100%"
                id="map"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlacementSidebar from '../../Sidebar/PlacementSidebar.vue'
import 'leaflet-arrowheads'
import '@/assets/js/leaflet-contextmenu/leaflet.contextmenu.js'

import '@/assets/css/leaflet-contextmenu/leaflet.contextmenu.css'
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
// Leaflet
import L from 'leaflet'
// Leaflet Pulse Plugin
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css'
import 'leaflet-pulse-icon'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import InitData from '../../components/InitData.vue'
import * as DestroyUtils from '@/assets/js/utils/destroy-utils.js'
import hdate from 'human-date'
import date from 'date-and-time'

import Vue from 'vue'
import { mapState } from 'vuex'
import { slotProps } from '@braid/vue-formulate'
import { getMaxSpaces } from '@/assets/js/utils/hoverlay-utils.js'




// import InputTag from 'vue-input-tag'
// import VueTagsInput from '@johmun/vue-tags-input'

export default {
  props: { spaces_labels: {}, labels: {}, spaces: {} },
  components: {
    PlacementSidebar,
    InitData,
    // InputTag,
    // VueTagsInput,
  },
  data() {
    return {
      showPopupOnboarding: false,
      selected: [],
      selectedSpaceLabels: [],
      labelsFilter: [],
      popupLabelCreation: false,
      itemsPerPage: 8,
      isListViewEnabled: false,
      // Leaflet
      leafletMap: null,
      address: '',
      layer: [],
      maxSpaces: getMaxSpaces(),
      isLayerLoaded: false,
      selectedPlacements: {},
      isSidebarPlacementActive: false,
      pulsingIconDisable: null,
      pulsingIconEnable: null,
      placementIndex: 0,
      uniquePlacements: [],
      latlongCircleDictionnary: {},
      circlesPlacementDictionnary: {},
      arrowVector: null,
      circleArray: null,
      thumbnailCluster: null,
      thumbnailMarkersDic: {},
      circleMarkersDic: {},
      RoadmapLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 21,
      }),
      TerrainLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 21,
      }),
      AlteredRoadmapLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 21,
      }),
      SatelliteOnlyLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 21,
      }),
      TerrainOnlyLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 21,
      }),
      HybridLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}', {
        attribution: '&copy;',
        maxNativeZoom: 19,
        maxZoom: 21,
      }),
    }
  },
  watch: {
    selectedSpaceLabels: {
      handler(updatedValue, previousValue) {
        // discard if value is null
        if (updatedValue == null || previousValue == null) return
        let filteredArray
        // A label has been added to a space
        if (updatedValue.length > previousValue.length) {
          filteredArray = updatedValue.filter(a => !previousValue.includes(a))
          this.$store.dispatch('hoverlay/AddLabelToSpace', {
            label_pid: filteredArray[0],
            space_pid: this.selected.pid,
          })
        }
        // A label has been remove from a space
        else {
          filteredArray = previousValue.filter(a => !updatedValue.includes(a))
          this.$store.dispatch('hoverlay/RemoveLabelToSpace', {
            label_pid: filteredArray[0],
            space_pid: this.selected.pid,
          })
        }
      },
    },
    isListViewEnabled: {
      handler(updatedValue) {
        this.$store.dispatch('hoverlay/updateSpaceListView', updatedValue)
      },
    },
    isLayerLoaded: {
      handler(updatedValue) {
        if (updatedValue == true) {
          this.$vs.loading.close()
        }
      },
    },
    spaces: {
      handler(updatedValue) {},
    },
    selected: {
      handler(updatedValue, oldValue) {
        // Update selectedSpaceLabels
        // Reset selectedSpaceLabels to null
        this.selectedSpaceLabels = null
        var temp_spaces_labels = []
        this.spaces_labels.forEach(anchor_space => {
          console.log(anchor_space)
          if (anchor_space.space_pid == updatedValue.pid && anchor_space.label)
            temp_spaces_labels.push(anchor_space.label.pid)
        })
        // Update selectedSpaceLabels on the next tick
        Vue.nextTick(() => {
          this.selectedSpaceLabels = temp_spaces_labels
        })

        // When the user click on placement on the map, the table refresh so it moves the user
        // to the corresponding page to show the selected placement
        var index = this.spaces.indexOf(updatedValue)
        var pageIndex = parseInt(index / this.itemsPerPage) + 1
        this.$refs.table.currentx = pageIndex

        // Zoom on the map to show the selected placement
        this.leafletMap.panTo(new L.LatLng(updatedValue.latitude, updatedValue.longitude))

        let popup = L.popup({ closeButton: false })
        popup.setLatLng([updatedValue.latitude, updatedValue.longitude])
        popup.setContent(
          this.createHtmlPopup(updatedValue.pid, updatedValue.name, updatedValue.latitude, updatedValue.longitude)
        )
        popup.openOn(this.leafletMap)

        this.leafletMap.setView([updatedValue.latitude, updatedValue.longitude], 17)
      },
    },
  },
  computed: {
    labelsFilterLength() {
      return this.labelsFilter.length
    },
    spacesCountMsg() {
      const spacesFormat = this.spacesCount === 1 ? 'space' : 'spaces'
      if (this.maxSpaces === '') return `${this.spacesCount} ${spacesFormat} created`
      return `${this.spacesCount} of ${this.maxSpaces} available spaces`
    },
    spacesLimitReached() {
      return this.maxSpaces === '' ? false : this.spacesCount >= this.maxSpaces
    },
    filteredSpaces() {
      if (this.labelsFilter == undefined || this.labelsFilter == null || this.labelsFilter.length < 1)
        return this.spaces
      else {
        var s = []
        this.spaces.forEach(space => {
          console.log(space.pid)
          this.spaces_labels.forEach(space_label => {
            if (
              space.pid == space_label.space_pid &&
              space_label.label &&
              this.labelsFilter.includes(space_label.label.pid)
            )
              if (!s.some(e => e.pid === space.pid))
                // add space if not already in list
                s.push(space)
          })
        })
        return s
      }
    },
    labelOptions() {
      var labelOptions = []
      labelOptions.push({ text: 'All', value: undefined })
      if (this.labels)
        this.labels.forEach(label => {
          labelOptions.push({ text: label.name, value: label.pid })
        })
      return labelOptions
    },
    currentPage() {
      if (this.isMounted) {
        console.log('currentPage = ' + this.$refs.table.currentx)
        return this.$refs.table.currentx
      }
      return 1
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.spaces.length
    },
    ...mapState({
      spacesCount: state => state.hoverlay.spaces.length
    })
  },
  async mounted() {
    if (this.spaces.length <= 0) this.showPopupOnboarding = true

    // Leaflet map creation
    this.leafletMap = L.map('map', {
      contextmenu: false,
      contextmenuItems: [],
      center: [0, 0],
      layers: [
        this.TerrainLayer,
        this.SatelliteOnlyLayer,
        this.HybridLayer,
        this.AlteredRoadmapLayer,
        this.RoadmapLayer,
      ],
      maxBounds: [
        [-90, -180],
        [90, 180],
      ],
      minZoom: 2,
      zoom: 4,
    })
    L.control.locate().addTo(this.leafletMap)

    var baseLayers = {
      Terrain: this.TerrainLayer,
      // "Terrain Only": this.TerrainOnlyLayer,
      Satellite: this.SatelliteOnlyLayer,
      Hybrid: this.HybridLayer,
      'Altered Road Map': this.AlteredRoadmapLayer,
      'Road Map': this.RoadmapLayer,
    }
    var context = this

    // await HoverlayUtils.resolveAfterSeconds(0.2)

    this.leafletMap.on('popupopen', function(e) {
      var Editbuttons = document.querySelectorAll('.edit-placement-button')
      for (var i = 0; i < Editbuttons.length; i++) {
        var button = Editbuttons[i]
        button.onclick = null
        button.onclick = function(event) {
          var pid = button.getAttribute('data-pid')
          context.editSpace(pid)
        }
      }

      var EditSettingsButton = document.querySelectorAll('.edit-settings-placement-button')
      for (i = 0; i < EditSettingsButton.length; i++) {
        var editSettingButton = EditSettingsButton[i]
        editSettingButton.onclick = null
        editSettingButton.onclick = function(event) {
          var pid = button.getAttribute('data-pid')
          context.EditSpaceSettings(pid)
        }
      }

      var deleteButtons = document.querySelectorAll('.delete-placement-button')
      for (i = 0; i < deleteButtons.length; i++) {
        var deleteButton = deleteButtons[i]
        deleteButton.onclick = null
        deleteButton.onclick = function(event) {
          var pid = button.getAttribute('data-pid')
          context.confirmDeleteSpace(pid)
        }
      }
    })

    L.control.layers(baseLayers).addTo(this.leafletMap)

    this.layer = new L.FeatureGroup().addTo(this.leafletMap)

    this.pulsingIconEnable = L.icon.pulse({
      iconSize: [12, 12],
      // color: 'red',
      color: '#7367F0',
      heartbeat: 0.85,
    })

    this.pulsingIconDisable = L.icon.pulse({
      iconSize: [13, 13],
      heartbeat: 0,
      color: '#EA5455',
      // fillColor : "#EA5455",
      // color: 'gray',
      animate: false,
    })

    this.createMap()
    this.centerMap()
  },
  async created() {
    if (this.$store.state.hoverlay.preferences)
      this.isListViewEnabled = this.$store.state.hoverlay.preferences.is_space_list_view_enabled
  },
  beforeDestroy() {
    this.disposeScene()
  },
  methods: {
    EditSpaceLabels() {
      this.$router.push({
        path: `/pages/user-settings#labels`,
      })
    },
    EditSpaceSettings(pid) {
      this.$router.push({
        path: `/edit-space-settings/` + pid,
      })
    },
    createHtmlPopup(pid, description, latitude, longitude) {
      return `<center><h5>${description}</h5> <br><span><!---->
      <span class="edit-placement-button" data-pid="${pid}"  class="ml-2  feather-icon select-none relative">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit w-6 h-6 text-gray hover:text-primary stroke-current"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
      </span>
      <span class="edit-settings-placement-button" data-pid="${pid}" class="ml-2  feather-icon select-none relative">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings w-6 h-6 hover:text-primary stroke-current"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
      </span>
      <span class="delete-placement-button" data-pid="${pid}" class="ml-2  feather-icon select-none relative">
      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash w-6 h-6 hover:text-danger stroke-current"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg></span><!----></span>`
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async refresh() {
      this.$vs.loading()
      await this.$store.dispatch('hoverlay/fetchAnchors', this.modifiedPlacements)
      try {
        await this.$store.dispatch('hoverlay/getLayerContent')
        this.isLayerLoaded = true
        this.$vs.notify({
          title: 'Refresh',
          text: 'Your latest content has been fetch',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        })
      } catch (error) {
        console.log(error.message)
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      } finally {
        this.$vs.loading.close()
        this.createMap()
        this.centerMap()
      }
    },
    addNew() {
      this.$router.push({
        path: `/create-and-place?centerLat=${this.leafletMap.getCenter().lat}&centerLng=${
          this.leafletMap.getCenter().lng
        }&zoom=${this.leafletMap.getZoom()}`,
      })
    },
    zoomPlacement(latitude, longitude) {
      this.leafletMap.setView([latitude, longitude], 17)
    },
    editSpace(pid) {
      console.log('editSpace')
      const path = `/space/${pid}`
      console.log(path)
      if (this.$route.path !== path)
        this.$router.push(path).catch(err => {
          console.log(err)
        })
    },
    confirmDeleteSpace(pid) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('MySpaceDeletionTitle'),
        text: this.$t('MySpaceDeletionDescription'),
        acceptText: this.$t('Delete'),
        accept: this.deleteSpace,
        parameters: [pid],
      })
    },
    deleteSpace(pid) {
      this.leafletMap.closePopup()
      console.log(this.spaces.length)

      console.log('deleteSpace' + pid[0])
      this.$store
        .dispatch('hoverlay/deleteSpace', pid[0])
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'success',
            title: 'Success',
            text: 'the space has been deleted! ',
          })
          this.removeMarker(pid[0])
          // this.centerMap()
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    getVisibilityColor(placement) {
      // if (placement === 'on_hold') return 'warning'
      if (new Date(placement.present_to) < new Date()) return 'danger'
      if (placement.access_level === 'owner') return 'warning'
      if (placement.access_level === 'everyone') return 'primary'
      return 'primary'
    },
    removeMarker(pid) {
      // remove marker from the cluster
      this.thumbnailCluster.removeLayer(this.thumbnailMarkersDic[pid])
      // remove the cirlce from the map
      this.layer.removeLayer(this.circleMarkersDic[pid])
      // remove markers from dictionnaries
      delete this.circleMarkersDic[pid]
      delete this.thumbnailMarkersDic[pid]
    },
    centerMap() {
      if (this.circleArray && this.circleArray.length > 0) {
        var group = new L.featureGroup(this.circleArray)
        this.leafletMap.fitBounds(group.getBounds())
      }
    },
    createMap() {
      this.circleArray = []
      this.thumbnailCluster = new L.MarkerClusterGroup()

      this.spaces.forEach(space => {
        var containerElement = document.createElement('div')
        containerElement.style.width = `60px`
        containerElement.style.height = `60px`
        containerElement.style.backgroundSize = '100%'
        containerElement.style.position = 'absolute'

        var thumbnailElement = document.createElement('div')
        containerElement.appendChild(thumbnailElement)

        thumbnailElement.className = 'space-marker'
        thumbnailElement.id = `space-${space.pid}`
        thumbnailElement.setAttribute('space_pid', space.pid)

        console.log(new Date() < new Date(space.present_to))
        if (new Date() < new Date(space.present_to)) thumbnailElement.classList.add('space-marker-not-expired')
        else thumbnailElement.classList.add('space-maker-expired')

        // Create a DOM element for each marker
        if (space.thumbnail_small) thumbnailElement.style.backgroundImage = `url(${space.thumbnail_small})`
        else thumbnailElement.style.backgroundImage = `url(${require('@/assets/images/hoverlay/Icon-512.png')})`
        thumbnailElement.style.width = `60px`

        thumbnailElement.style.height = `60px`
        thumbnailElement.style.backgroundSize = '100%'
        thumbnailElement.style.position = 'absolute'

        if (space.description && space.description != 'null') {
          var descriptionElement = document.createElement('div')
          descriptionElement.className = 'marker-description'
          descriptionElement.id = `description-${space.pid}`
          var width = 120
          if (space.description.length < 12) {
            width = space.description.length * 18
            descriptionElement.style.top = '20px'
          }
          descriptionElement.style.width = `${width}px`
          descriptionElement.innerHTML = space.description
          containerElement.appendChild(descriptionElement)
        }

        var icon = L.divIcon({
          html: containerElement.outerHTML,
          iconSize: [60, 60],
          iconAnchor: [30, 30],
        })

        var thumbnailMarker = L.marker([space.latitude, space.longitude], { icon: icon }).on(
          'click',
          this.OnMarkerClick
        )
        this.thumbnailMarkersDic[space.pid] = thumbnailMarker
        this.thumbnailCluster.addLayer(thumbnailMarker)

        // .addTo(this.layer)

        // marker = L.marker([space.latitude, space.longitude], {
        //   icon: new Date() < new Date(space.present_to) ? this.pulsingIconEnable : this.pulsingIconDisable,
        //   contextmenu: true,
        // })

        thumbnailMarker.bindPopup(this.createHtmlPopup(space.pid, space.name, space.latitude, space.longitude))

        var circle = L.circle([space.latitude, space.longitude], space.range, {
          // color: '#7367F0',
          color: '#333333',

          // fillColor: '#7367F0',
          fillOpacity: 0,
          opacity: 0.1,
          weight: 2,
          contextmenu: true,
        }).addTo(this.layer)
        // .on('click', this.OnMarkerClick)
        circle.bindPopup(this.createHtmlPopup(space.pid, space.name, space.latitude, space.longitude))

        this.circleArray.push(circle)
        this.circleMarkersDic[space.pid] = circle

        this.latlongCircleDictionnary[`${space.latitude};${space.longitude}`] = circle

        var popupContent = space.name.substring(0, 30)

        thumbnailMarker.bindTooltip(popupContent, {
          permanent: false,
          sticky: true,
          className: 'my-label',
          offset: [0, 0],
        })
        circle.bindTooltip(popupContent, { permanent: false, sticky: true, className: 'my-label', offset: [0, 0] })
      })
      this.leafletMap.addLayer(this.thumbnailCluster)
    },

    togglePlacementSidebar(val = false) {
      this.isSidebarPlacementActive = val
    },
    openPlacementSidebar() {
      this.togglePlacementSidebar(true)
    },
    getSpaceLabels(space) {
      var spaces_labels = []
      this.spaces_labels.forEach(sl => {
        if (sl.space_pid == space.pid && sl.label) spaces_labels.push(sl)
      })
      console.log('spaces_labels')
      console.log(spaces_labels)
      return spaces_labels
    },
    createLabel() {},
    onLabelChanged() {},
    AdressSearch: function() {
      this.$vs.loading()

      this.$store
        .dispatch('hoverlay/getLatLongFromAddress', this.address)
        .then(response => {
          this.$vs.loading.close()

          var firstResult = response[0]

          var bounds = L.latLngBounds(
            [firstResult.boundingbox[0], firstResult.boundingbox[2]],
            [firstResult.boundingbox[1], firstResult.boundingbox[3]]
          )

          this.leafletMap.flyToBounds(bounds)

          this.currentPage = 1
        })
        .catch(err => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: 'Could not find the requested location',
            color: 'danger',
          })
          console.error(err)
        })
    },
    createNewPlacement(coordinates) {
      console.log('createNewPlacement')
      this.$router.push({
        path: `/create/${coordinates.lat}/${coordinates.lng}`,
      })
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    OnMarkerClick(marker) {
      console.log('OnMarkerClick')
      console.log(marker)
      this.spaces.forEach(placement => {
        if (placement.latitude == marker.latlng.lat && placement.longitude == marker.latlng.lng) {
          this.selected = placement
          return
        }
      })
    },
    showCoordinates(e) {
      alert(e.latlng)
    },
    dateToDuration(time) {
      var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(time)
      return hdate.relativeTime(d)
    },
    dateToString(time) {
      return date.format(new Date(time), 'ddd, MMM DD YYYY') // => 'Fri, Jan 02 2015'
    },
    disposeScene() {
      DestroyUtils.destroyLeaflet(
        {
          leafletMap: this.leafletMap,
          layers: [
            this.RoadmapLayer,
            this.TerrainLayer,
            this.AlteredRoadmapLayer,
            this.SatelliteOnlyLayer,
            this.TerrainOnlyLayer,
            this.HybridLayer,
          ],
        },
        this
      )

      this.selected = []
      this.itemsPerPage = 5
      this.leafletMap = null
      this.address = ''
      this.layer = []
      this.isLayerLoaded = false
      this.selectedPlacements = {}
      this.isSidebarPlacementActive = false
      this.pulsingIconDisable = null
      this.pulsingIconEnable = null
      this.placementIndex = 0
      this.uniquePlacements = []
      this.latlongCircleDictionnary = {}
      this.circlesPlacementDictionnary = {}
      this.arrowVector = null
      this.circleArray = null
      this.thumbnailCluster = null
      this.thumbnailMarkersDic = null
      this.circleMarkersDic = null
      this.RoadmapLayer = null
      this.TerrainLayer = null
      this.AlteredRoadmapLayer = null
      this.SatelliteOnlyLayer = null
      this.TerrainOnlyLayer = null
      this.HybridLayer = null
    },
  },
}
</script>

<style lang="scss">
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player {
  border-radius: 7px;
  overflow: hidden;
  z-index: 1;
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
}

.blinking {
  animation: fade 1s infinite alternate;
}

// Hover on actions button labels, edit, settings, delete
.custom-tr:hover > td:last-child > span > * {
  color: #b7b7b7;
}
.custom-tr:hover > td > span > button > span {
  color: #b7b7b7;
}

.light-gray {
  // visibility: hidden !important;
  color: #eaeaea;
}

.gray {
  // visibility: hidden !important;
  color: #afafaf;
}
.custom-tr > td {
  padding: 12px !important;
}
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.marker {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
}
.leaflet-div-icon {
  background: none;
  border: none;
}
.space-marker {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s; */
  /* overflow: hidden; */
}
.space-marker-not-expired {
  border: 3px solid rgb(255, 255, 255);
}

.space-maker-expired {
  border: 3px solid rgb(255, 0, 0);
}

.marker-description {
  visibility: hidden;
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  box-sizing: border-box;
  padding: 4px 10px 5px 27px;
  border-radius: 10px;
  left: 45px;
  top: 10px;
  /* width: 130px; */
  /* margin-left: 10px; */
  /* margin-top: 10px; */
  /* max-width: 165.352px; */
  overflow: hidden;
  max-height: 46px;
  background-color: #ffffff;
  z-index: -9999999;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}
.flex-end {
  justify-content: flex-end;
}

// Customize space tags
.vue-input-tag-wrapper .input-tag {
  background-color: #7367f0;
  border: 1px solid #7367f0;
  color: #ffffff;
}
.vue-input-tag-wrapper .input-tag .remove {
  cursor: pointer;
  font-weight: 700;
  color: #ffffff;
}
.flex {
  display: flex;
  // flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
</style>
